import type { WZoomMeetingView, WZoomUserView } from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../actions/types/ZoomActionTypes';
import type { ZoomActionTypes } from '../actions/types/ZoomActionTypes';

export type ZoomReducerStateType = {
  planType?: WZoomUserView.TypeEnum | null;
  meeting: WZoomMeetingView;
  connectionSettings: ActionTypes.MeetingSignatureType | Record<string, never>;
};

export const initialState = {
  connection: {},
  code: null,
  accessToken: {},
  meeting: {},
  connectionSettings: {},
  planType: null,
};

const zoomReducer = (state = initialState, action: ZoomActionTypes): ZoomReducerStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_ACCESS_TOKEN: {
      const { code } = action.payload;
      return Object.assign({}, state, {
        code,
        accessToken: {},
      });
    }
    case ActionTypes.RECEIVE_ACCESS_TOKEN: {
      const accessToken = action.payload;
      return Object.assign({}, state, {
        accessToken,
        code: null,
      });
    }
    case ActionTypes.REMOVE_ACCESS_TOKEN: {
      return Object.assign({}, state, {
        accessToken: {},
        connection: {},
        code: null,
      });
    }
    case ActionTypes.REQUEST_MEETING: {
      return Object.assign({}, state, {
        meeting: {},
      });
    }
    case ActionTypes.RECEIVE_MEETING: {
      const meeting = action.payload;
      return Object.assign({}, state, {
        meeting,
      });
    }
    case ActionTypes.REQUEST_CONNECTION_SETTINGS: {
      return Object.assign({}, state, {
        connectionSettings: {},
      });
    }
    case ActionTypes.RECEIVE_CONNECTION_SETTINGS: {
      const connectionSettings = action.payload;
      return Object.assign({}, state, {
        connectionSettings,
      });
    }
    case ActionTypes.REQUEST_CONNECTION: {
      return Object.assign({}, state, {
        connection: {},
      });
    }
    case ActionTypes.RECEIVE_CONNECTION: {
      const connection = action.payload;
      return Object.assign({}, state, {
        connection,
      });
    }
    case ActionTypes.RECEIVE_ZOOM_PLAN_TYPE: {
      const planType = action.payload.type;
      return {
        ...state,
        planType,
      };
    }
    default:
      return state;
  }
};

export default zoomReducer;
