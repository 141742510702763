import {
  ReviewTYOrder,
  InvitesPaperUpsellAction,
  SET_REVIEW_TY_ORDER,
} from 'cards/actions/invitesPaperUpsell';

export interface InvitesPaperUpsellState {
  reviewTYOrder: ReviewTYOrder | null;
}

const initialState: InvitesPaperUpsellState = {
  reviewTYOrder: null,
};

const invitesPaperUpsellReducer = (
  state = initialState,
  action: InvitesPaperUpsellAction
): InvitesPaperUpsellState => {
  switch (action.type) {
    case SET_REVIEW_TY_ORDER: {
      return {
        ...state,
        reviewTYOrder: action.payload,
      };
    }
    default:
      return state;
  }
};

export default invitesPaperUpsellReducer;
