/**
 * @prettier
 */

import _isEqual from 'lodash/isEqual';
import { ProjectActionTypes } from '../actions/projectActionTypes';

const initialState = {
  busy: false,
  selectedGuestIds: [],
  fontSizeOverrides: null,
  isFirstFontResizeComplete: false,
};

const recipients = (state = initialState, action) => {
  switch (action.type) {
    case ProjectActionTypes.REQUEST_PROJECT_GUEST_IDS: {
      return { ...state, busy: true };
    }
    case ProjectActionTypes.RECEIVE_PROJECT_GUEST_IDS: {
      const { projectGuestIds } = action.payload;
      return { ...state, busy: false, selectedGuestIds: projectGuestIds };
    }
    case ProjectActionTypes.RESET_SELECTED_GUEST_IDS: {
      return initialState;
    }
    case ProjectActionTypes.COMPLETE_FONT_RESIZE: {
      return {
        ...state,
        isFirstFontResizeComplete: true,
      };
    }
    case ProjectActionTypes.OVERRIDE_FONT_SIZES: {
      const { fontSizeOverrides } = action.payload;
      return {
        ...state,
        fontSizeOverrides,
      };
    }
    case ProjectActionTypes.RECEIVE_PROJECT_GUESTS: {
      const { projectGuests } = action.payload;
      const newSelectedGuestIds = projectGuests.map(guest => guest.guest_group.id);
      const selectedGuestIds = _isEqual(newSelectedGuestIds, state.selectedGuestIds)
        ? state.selectedGuestIds
        : newSelectedGuestIds;
      const fontSizeOverrides = projectGuests.reduce((acc, guest) => {
        const {
          guest_name_font_size_override: name,
          address_font_size_override: address,
          guest_group: { id },
        } = guest;

        acc[id] = { address, name };
        return acc;
      }, {});

      return {
        ...state,
        busy: false,
        selectedGuestIds,
        fontSizeOverrides,
      };
    }
    default:
      return state;
  }
};

export default recipients;
