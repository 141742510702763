import type { WebsitePreviewReducerState } from 'reducers/website/websitePreview';

export const UPDATE_PREVIEW = 'zola/website/page/UPDATE_PREVIEW';

type UpdatePreviewType = {
  type: typeof UPDATE_PREVIEW;
  payload: Partial<WebsitePreviewReducerState>;
};

export type WebsitePreviewActionTypes = UpdatePreviewType;
