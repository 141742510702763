import type { ZolaFileResponseType } from 'actions/types/UploadcareActions';

export const FILE_UPLOADING = 'zola/wedding/website/FILE_UPLOADING';
export const FILE_UPLOADED = 'zola/wedding/website/FILE_UPLOADED';
export const FILE_TEMP_UPLOADED = 'zola/wedding/website/FILE_TEMP_UPLOADED';
export const STORE_REFERENCE_KEY = 'zola/wedding/website/STORE_REFERENCE_KEY';

type FileUploadingType = {
  type: typeof FILE_UPLOADING;
  payload: string;
};

type FilePreviewValueType = Partial<ZolaFileResponseType> & { busy?: boolean };

export type FilePreviewType = Record<string, FilePreviewValueType>;

type FileUploadedType = {
  type: typeof FILE_UPLOADED;
  payload: FilePreviewType;
};

type StoreReferenceKeyType = {
  type: typeof STORE_REFERENCE_KEY;
  payload: string;
};

type FileTempUploadedType = {
  type: typeof FILE_TEMP_UPLOADED;
  payload: FilePreviewType;
};

export type FileUploadActionTypes =
  | FileUploadingType
  | FileUploadedType
  | StoreReferenceKeyType
  | FileTempUploadedType;
