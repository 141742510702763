export interface ReviewTYOrder {
  suiteUUID: string;
  variationUUID: string;
  envelopeVariationUUID: string | null;
  quantity: number;
  folded: boolean;
}

export const SET_REVIEW_TY_ORDER = 'SET_REVIEW_TY_ORDER';
export interface SetReviewTYOrder {
  type: typeof SET_REVIEW_TY_ORDER;
  payload: ReviewTYOrder | null;
}

export type InvitesPaperUpsellAction = SetReviewTYOrder;

export const setReviewTYOrder = (payload: ReviewTYOrder | null): InvitesPaperUpsellAction => ({
  type: SET_REVIEW_TY_ORDER,
  payload,
});
