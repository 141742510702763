const URL_PATTERN = new RegExp(/^.+\..+$/, 'i');

export function isValidHttpUrl(urlText: string): boolean {
  let url;

  try {
    const urlTextLowercase = urlText.toLowerCase();
    if (urlTextLowercase.startsWith('https:') || urlTextLowercase.startsWith('http:')) {
      // Try to build URL object if url starts with http: or https:
      url = new URL(urlText);
    }

    // if no http or https in url, match if url is of form string1.string2 and attempt to make a URL object
    if (URL_PATTERN.test(urlText)) {
      url = new URL(`http://${urlText}`);
    }
  } catch (e) {
    return false;
  }

  return !!(url?.protocol === 'http:' || url?.protocol === 'https:');
}
