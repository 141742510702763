import type { Layout } from 'cards/types/Layouts';
import {
  ReversePrintingLayoutsAction,
  UPDATE_LAYOUTS_STATE,
} from 'cards/actions/reversePrintingLayoutsActionTypes';

export interface ReversePrintingLayoutsState {
  layouts: Layout[];
  loading: boolean;
  isRecroppingInProgress: boolean;
  uuid: string | null;
}

const initialState: ReversePrintingLayoutsState = {
  layouts: [],
  loading: false,
  isRecroppingInProgress: false,
  uuid: null,
};

const reversePrintingLayoutsReducer = (
  state = initialState,
  action: ReversePrintingLayoutsAction
): ReversePrintingLayoutsState => {
  switch (action.type) {
    case UPDATE_LAYOUTS_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default reversePrintingLayoutsReducer;
