import _includes from 'lodash/includes';
import _without from 'lodash/without';
import _values from 'lodash/values';

import * as ActionTypes from '../actions/types/SeatingChartActionTypes';

import { reorderArrayItem } from './util/seatingChartUtils';

const initialState = {
  busy: false,
  byEventId: {},
  events: [],
  seatingChart: {},
  orderedTableUUIDs: [],
  tablesByUUID: {},
  onboarding: false,
  step: 0,
  event: {},
  updateSeatsRequest: [],
  selectedGuests: { guests: [], couple: [] },
  draggingSeatInProgress: false,
  activeSeatInput: null,
  guestSearchSet: {},
  guestsByID: {},
  seatsByUUID: {},
  bannerID: null,
  bannerText: null,
  bannerType: null,
};

// TODO: map table.seats to array of uuids, not objects
const seatingChartReducer = (state = initialState, action) => {
  switch (action.type) {
    // busy
    case ActionTypes.REQUEST_CHART: {
      return { ...state, busy: true };
    }
    // busy
    case ActionTypes.REQUEST_CHARTS: {
      return { ...state, busy: true };
    }
    // busy
    case ActionTypes.CREATE_CHART: {
      return { ...state, busy: true };
    }
    // busy, seatingChart, tablesByUuid, guestSearchSet, seatsByUUID
    case ActionTypes.RECEIVE_CHART: {
      const seatingChart = action.payload.data;
      const seatingChartTables = action.payload.data.tables;

      // construct guestSearchSet
      const guestGroups = action.payload.data.guests.guest_list;
      const guestSearchSet = Object.keys(guestGroups)
        // create array of groups
        .reduce((acc, key) => acc.concat(guestGroups[key]), [])
        // concat all the guests from the groups
        .reduce((acc, group) => {
          const affiliation = group.guest_affiliation;
          // re-shape each guest in the array
          const guests = group.guests.map(guest => ({
            [guest.guest_view.id]: {
              affiliation,
              displayName: guest.display_name,
              groupID: guest.guest_view.guest_group_id,
              id: guest.guest_view.id,
              seatUUID: guest.seat_uuid,
              tableUUID: guest.table_uuid,
            },
          }));
          return acc.concat(guests);
        }, [])
        // convert to obj, not array
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});

      // guestsByID
      const guestsByID = Object.keys(guestGroups)
        .reduce((acc, key) => [...acc, guestGroups[key]], [])

        // flat array of all guests groups
        .reduce((acc, affilationSet) => [...acc, ...affilationSet], [])

        // flat array of all guests
        .reduce((acc, guestGroup) => {
          const affiliation = guestGroup.guest_affiliation;
          // reshape each guest object (w/affiliation)
          const guests = guestGroup.guests.map(guest => ({
            id: guest.guest_view.id,
            groupID: guest.guest_view.guest_group_id,
            affiliation,
            mealOptionID: guest.guest_view.meal_option_id,
            rsvpType: guest.rsvp_type,
            displayName: guest.display_name,
            seatUUID: guest.seat_uuid,
            tableUUID: guest.table_uuid,
            // ¿ guest.rsvp_type -or- guest.guest_view.rsvp ?
          }));
          return [...acc, ...guests];
        }, [])
        // convert to a keyed object
        .reduce(
          (acc, guest) => ({
            ...acc,
            [guest.id]: guest,
          }),
          {}
        );

      // seatsByUUID
      const seatsByUUID = seatingChartTables
        .reduce((acc, table) => [...acc, ...table.seats], [])
        .reduce(
          (acc, seat) => ({
            ...acc,
            [seat.uuid]: {
              ...seat,
              isEmpty: seat.couple_role === null && seat.guest_id === null,
            },
          }),
          {}
        );

      // couples for guestSearchSet
      const coupleData = action.payload.data.guests.couple;
      const couple = {
        OWNER: {
          affiliation: 'OWNER',
          displayName: `${coupleData.primary_first_name} ${coupleData.primary_last_name}`,
          groupID: null,
          id: 'OWNER',
          seatUUID: coupleData.primary_seat_uuid,
          tableUUID: coupleData.primary_table_uuid,
        },
        PARTNER: {
          affiliation: 'PARTNER',
          displayName: `${coupleData.partner_first_name} ${coupleData.partner_last_name}`,
          groupID: null,
          id: 'PARTNER',
          seatUUID: coupleData.partner_seat_uuid,
          tableUUID: coupleData.partner_table_uuid,
        },
      };

      const tablesByUUID = seatingChartTables.reduce((acc, table) => {
        const seats = table.seats.map(seat => seat.uuid);
        const numFilledSeats = seats.filter(uuid => !seatsByUUID[uuid].isEmpty).length;
        return {
          ...acc,
          [table.uuid]: { ...table, seats, num_filled_seats: numFilledSeats },
        };
      }, {});
      const orderedTableUUIDs = Object.keys(tablesByUUID).sort(
        (a, b) => tablesByUUID[a].display_order - tablesByUUID[b].display_order
      );

      return {
        ...state,
        busy: false,
        guestSearchSet: Object.assign(guestSearchSet, couple),
        guestsByID,
        seatingChart,
        seatsByUUID,
        tablesByUUID,
        orderedTableUUIDs,
      };
    }
    // busy, byEventId, events
    case ActionTypes.RECEIVE_CHARTS: {
      const events = [];
      const seatingCharts = action.payload;
      const byEventId = { ...seatingCharts.event_to_charts_map };

      Object.keys(byEventId).forEach(key => {
        events.push({
          event: key,
          uuid: byEventId[key][0].uuid,
          name: byEventId[key][0].name,
        });
      });

      return {
        ...state,
        busy: false,
        byEventId,
        events,
      };
    }
    // orderedTableUUIDs, tablesByUuid
    case ActionTypes.REORDER_TABLES: {
      const { orderedTableUUIDs, tablesByUUID } = state;
      if (orderedTableUUIDs.length <= 0) return state;
      const { hoverIndex, sourceIndex } = action.payload;

      const newOrderedUUIDS = reorderArrayItem(orderedTableUUIDs, sourceIndex, hoverIndex);
      const newTablesByUUID = newOrderedUUIDS.reduce(
        (acc, uuid, index) => ({
          ...acc,
          [uuid]: { ...tablesByUUID[uuid], display_order: index },
        }),
        {}
      );

      return {
        ...state,
        orderedTableUUIDs: newOrderedUUIDS,
        tablesByUUID: newTablesByUUID,
      };
    }
    // seatsByUUID
    case ActionTypes.REORDER_SEATS: {
      const { hoverIndex, sourceIndex, tableUuid: tableUUID } = action.payload;
      const { seatsByUUID, tablesByUUID } = state;
      const table = tablesByUUID[tableUUID];
      if (table === null || table === undefined) return state;
      const reorderedSeats = reorderArrayItem(table.seats, sourceIndex, hoverIndex);
      const updatedSeats = reorderedSeats.reduce((acc, uuid, idx) => {
        const seat = seatsByUUID[uuid];
        return { ...acc, [uuid]: { ...seat, display_order: idx + 1 } };
      }, {});

      return {
        ...state,
        seatsByUUID: { ...seatsByUUID, ...updatedSeats },
      };
    }
    // updateSeatsRequest, guestSearchSet, seatsByUUID
    case ActionTypes.REORDER_SEATS_IN_TABLE: {
      const { seats } = action.payload;
      const updatedSeatsByUUID = seats.reduce(
        (acc, seat) => ({
          ...acc,
          [seat.uuid]: seat,
        }),
        {}
      );
      const updatedGuests = seats.reduce((acc, seat) => {
        const key = seat.couple_role === null ? seat.guest_id : seat.couple_role;
        const origGuest = state.seatsByUUID[key];
        return {
          ...acc,
          [key]: { ...origGuest, seatUUID: seat.uuid },
        };
      }, {});
      const seatsByUUID = {
        ...state.seatsByUUID,
        ...updatedSeatsByUUID,
      };
      const guestSearchSet = {
        ...state.guestSearchSet,
        ...updatedGuests,
      };
      return {
        ...state,
        guestSearchSet,
        seatsByUUID,
        updateSeatsRequest: seats,
      };
    }
    // updateSeatsRequest, guestSearchSet, seatsByUUID
    case ActionTypes.ADD_PEOPLE_TO_TABLE: {
      const { tableUuid } = action.payload;
      const { guestSearchSet, selectedGuests: selectedPeople, seatsByUUID, tablesByUUID } = state;
      const { couple: selectedCouple, guests: selectedGuests } = selectedPeople;
      // TODO: find where 'undefined' gets added to the selection
      const selectedPeopleSet = selectedCouple
        .concat(selectedGuests)
        .filter(key => key !== undefined);
      // const selectedPeopleSet = selectedCouple.concat(selectedGuests);
      const targetTable = tablesByUUID[tableUuid];

      // gather post-move seats:
      const targetSeatUUIDs = targetTable.seats.filter(uuid => seatsByUUID[uuid].isEmpty);
      const oldSeatUUIDs = selectedPeopleSet
        .map(key => guestSearchSet[key].seatUUID)
        .filter(uuid => uuid !== null);

      // populate target seats
      const filledSeats = selectedPeopleSet.reduce((acc, key, idx) => {
        const personType = typeof key === 'string' ? 'couple_role' : 'guest_id';
        const person = guestSearchSet[key];
        const targetSeat = seatsByUUID[targetSeatUUIDs[idx]];
        const seat = {
          ...targetSeat,
          couple_role: personType === 'couple_role' ? key : null,
          guest_id: personType === 'guest_id' ? key : null,
          display_name: person.displayName,
          hasDeclinedRSVP: person.hasDeclinedRSVP,
          isEmpty: false,
        };
        return { ...acc, [seat.uuid]: seat };
      }, {});
      // empty old seats
      const oldSeats = oldSeatUUIDs.reduce(
        (acc, uuid) => ({
          ...acc,
          [uuid]: {
            ...seatsByUUID[uuid],
            couple_role: null,
            guest_id: null,
            isEmpty: true,
            display_name: null,
            hasDeclinedRSVP: false,
          },
        }),
        {}
      );
      // update guest info
      const guestSearchSetUpdates = Object.keys(filledSeats).reduce((acc, uuid) => {
        const seat = seatsByUUID[uuid];
        const personType = seat.guest_id !== null ? 'guest_id' : 'couple_role';
        const personKey = guestSearchSet[seat[personType]];
        return {
          ...acc,
          [personKey]: {
            ...guestSearchSet[personKey],
            seatUUID: uuid,
            tableUUID: tableUuid,
          },
        };
      }, {});
      const updateSeatsRequest = _values(filledSeats).concat(_values(oldSeats));
      return {
        ...state,
        updateSeatsRequest,
        guestSearchSet: { ...guestSearchSet, ...guestSearchSetUpdates },
        seatsByUUID: { ...seatsByUUID, ...filledSeats, ...oldSeats },
      };
    }
    // tables, tablesByUUID, updateSeatsRequest, guestSearchSet, seatsByUUID
    case ActionTypes.ADD_PERSON_TO_SEAT: {
      const { updateSeatsRequest } = state;
      const { newSeat } = action.payload;
      const { guest_id: guestUUID, table_uuid: tableUUID, uuid: seatUUID } = newSeat;
      const occupantKey = guestUUID === null ? newSeat.couple_role : guestUUID;

      // compose the return values
      const newUpdateSeatsRequest = updateSeatsRequest.concat([newSeat]);
      const guestSearchSet = {
        ...state.guestSearchSet,
        [occupantKey]: {
          ...state.guestSearchSet[occupantKey],
          seatUUID,
          tableUUID,
        },
      };
      const seatsByUUID = { ...state.seatsByUUID, [seatUUID]: newSeat };
      return {
        ...state,
        updateSeatsRequest: newUpdateSeatsRequest,
        guestSearchSet,
        seatsByUUID,
      };
    }
    // updateSeatsRequest, guestSearchSet, seatsByUUID
    case ActionTypes.CLEAR_SEAT: {
      const { seatsByUUID: origSeatsByUUID, updateSeatsRequest } = state;
      // const { seat } = action.payload;
      const { seatUUID } = action.payload;
      // const { uuid: seatUUID } = seat;
      const oldSeat = origSeatsByUUID[seatUUID];
      const emptySeat = {
        ...oldSeat,
        isEmpty: true,
        guest_id: null,
        couple_role: null,
        display_name: null,
        hasDeclinedRSVP: false,
      };
      const occupantKey = oldSeat.guest_id === null ? oldSeat.couple_role : oldSeat.guest_id;

      // compose the return values
      const guestSearchSet = {
        ...state.guestSearchSet,
        [occupantKey]: {
          ...state.guestSearchSet[occupantKey],
          seatUUID: null,
          tableUUID: null,
        },
      };
      const seatsByUUID = { ...state.seatsByUUID, [seatUUID]: emptySeat };
      return {
        ...state,
        updateSeatsRequest: updateSeatsRequest.concat([emptySeat]),
        guestSearchSet,
        seatsByUUID,
      };
    }
    // onboarding
    case ActionTypes.TRIGGER_SEATING_ONBOARDING: {
      return {
        ...state,
        onboarding: true,
      };
    }
    // onboarding
    case ActionTypes.FINISH_ONBOARDING: {
      return {
        ...state,
        onboarding: false,
        step: 0,
      };
    }
    // onboarding, step
    case ActionTypes.ONBOARDING_SELECTION_STEP: {
      return {
        ...state,
        onboarding: true,
        step: 0,
      };
    }
    // onboarding, step
    case ActionTypes.ONBOARDING_TABLE_STEP: {
      return {
        ...state,
        onboarding: true,
        step: 2,
      };
    }
    // draggingSeatInProgress, seatsReorderedTable, selectedGuests, updateSeatsRequest
    case ActionTypes.CLEAR_SELECTED_GUESTS: {
      // clear the requests state and seatsReorderedTable
      // This is hit right before the update request is sent to the backend
      const { selectedGuests, updateSeatsRequest, draggingSeatInProgress } = initialState;
      return {
        ...state,
        selectedGuests,
        updateSeatsRequest,
        draggingSeatInProgress,
      };
    }
    // guestSearchSet, seatsByUUID, guestSearchSet
    case ActionTypes.UNSEAT_SELECTED_GUESTS: {
      const { guestSearchSet, seatsByUUID, selectedGuests } = state;
      const peopleKeys = selectedGuests.guests.concat(selectedGuests.couple);
      const seatsToEmpty = peopleKeys.map(key => guestSearchSet[key].seatUUID);
      // clear the guest data of seats for each person
      const unseatedGuests = peopleKeys.reduce((acc, key) => {
        const guest = guestSearchSet[key];
        return { ...acc, [key]: { ...guest, seatUUID: null, tableUUID: null } };
      }, {});
      // clear the seat data of guests for each person
      const updateSeatsRequest = seatsToEmpty.map(uuid => ({
        ...seatsByUUID[uuid],
        display_name: null,
        guest_id: null,
        couple_role: null,
        isEmpty: true,
      }));
      const emptiedSeats = seatsToEmpty.reduce(
        (acc, uuid) => ({
          ...acc,
          [uuid]: {
            ...seatsByUUID[uuid],
            display_name: null,
            guest_id: null,
            couple_role: null,
            isEmpty: true,
            hasDeclinedRSVP: false,
          },
        }),
        {}
      );

      return {
        ...state,
        guestSearchSet: { ...guestSearchSet, ...unseatedGuests },
        seatsByUUID: { ...seatsByUUID, ...emptiedSeats },
        updateSeatsRequest,
      };
    }
    // step
    case ActionTypes.INC_ONBOARDING_STEP: {
      return {
        ...state,
        step: state.step + 1,
      };
    }
    // step
    case ActionTypes.DEC_ONBOARDING_STEP: {
      return {
        ...state,
        step: state.step - 1,
      };
    }
    // event
    case ActionTypes.SET_NEW_CHART_EVENT: {
      const { event } = action.payload;
      return { ...state, busy: true, event };
    }
    // initialState
    case ActionTypes.DELETE_CHART: {
      return initialState;
    }
    // selectedGuests
    case ActionTypes.SELECT_GUESTS: {
      const { guest, isCouple } = action.payload;
      if (guest == null) return state;

      let guests = state.selectedGuests.guests.slice(); // Copy previous selected guests
      let couple = state.selectedGuests.couple.slice(); // Copy previous selected couple member

      if (isCouple) {
        const coupleRole = guest.couple_role;
        const alreadySelected = _includes(couple, coupleRole);

        if (alreadySelected) {
          couple = _without(couple, coupleRole); // Remove the couple member from the selection
        } else {
          couple.push(coupleRole); // Add the couple member to the selection
        }
      } else {
        const guestId = guest.guest_view != null ? guest.guest_view.id : guest.guest_id; // Guest_View only exists from guest rail
        const alreadySelected = _includes(guests, guestId);

        if (alreadySelected) {
          guests = _without(guests, guestId); // Remove the guest from the selection
        } else {
          guests.push(guestId); // add the guest to the selection
          const { guestGroup } = action.payload;
          // If the guest group exists, we're coming from the guest rail
          if (guestGroup != null && guest.guest_view.relationship_type === 'PRIMARY') {
            // For primary guests in the guest rail, we want to select their secondary guests automatically
            for (let i = 0; i < guestGroup.guests.length; i += 1) {
              const isSeated = guestGroup.guests[i].table_uuid != null;
              const additionalGuest = guestGroup.guests[i].guest_view;

              if (additionalGuest != null) {
                const checked = _includes(guests, additionalGuest.id);
                // If the secondary guest is not already checked, and not seated, add them to the selection
                if (!isSeated && !checked) {
                  guests.push(additionalGuest.id);
                }
              }
            }
          }
        }
      }

      return {
        ...state,
        selectedGuests: { couple, guests },
      };
    }
    // draggingSeatInProgress, seatsReorderedTable, selectedGuests
    // 👇 makes sure dragged inventory is accurate (drag target can be missed)
    case ActionTypes.DRAG_STARTED: {
      const { people } = action.payload;
      const { guests: guestSelection, couple: coupleSelection } = state.selectedGuests;
      const dragTargetKey = people[0];
      const isCouple = people.length === 1 && typeof dragTargetKey === 'string';
      // add drag item to selected guests && selected couple (only add to state later)
      const amendedCouple =
        coupleSelection.indexOf(dragTargetKey) > -1
          ? coupleSelection
          : coupleSelection.concat(people);
      const amendedGuests =
        guestSelection.indexOf(dragTargetKey) > -1 ? guestSelection : guestSelection.concat(people);

      // sometimes null gets passed in, clean it up
      const guests = amendedGuests.filter(elemKey => elemKey !== null && elemKey !== undefined);

      return {
        ...state,
        draggingSeatInProgress: true,
        selectedGuests: {
          couple: isCouple ? amendedCouple : coupleSelection,
          guests: !isCouple ? guests : guestSelection,
        },
      };
    }
    // draggingSeatInProgress
    case ActionTypes.DRAG_TABLE_STARTED: {
      return {
        ...state,
        draggingSeatInProgress: false,
      };
    }
    // draggingSeatInProgress, seatsReorderedTable
    case ActionTypes.DRAG_ENDED: {
      return {
        ...state,
        draggingSeatInProgress: false,
      };
    }
    // activeSeatInput
    case ActionTypes.SET_ACTIVE_SEAT_INPUT: {
      const { seatUUID } = action.payload;
      return { ...state, activeSeatInput: seatUUID };
    }
    // bannerText, bannerType, bannerID
    case ActionTypes.SHOW_BANNER: {
      const { bannerText, bannerType, bannerID } = action.payload;
      return { ...state, bannerText, bannerType, bannerID };
    }
    // bannerText, bannerType, bannerID
    case ActionTypes.HIDE_BANNER: {
      const { bannerID } = action.payload;
      return bannerID === state.bannerID // only kill the banner if latest ID matches
        ? { ...state, bannerText: null, bannerType: null }
        : state;
    }
    // busy
    case ActionTypes.HIDE_SPINNER: {
      return { ...state, busy: false };
    }
    default:
      return state;
  }
};

export default seatingChartReducer;
