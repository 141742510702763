import { schema } from 'normalizr';
import _omit from 'lodash/omit';
import type {
  WProjectCustomizationElementView,
  WProjectCustomizationFullView,
  WProjectCustomizationPageView,
  WProjectFullView,
} from '@zola/svc-web-api-ts-client';

const elementEntity = new schema.Entity<WProjectCustomizationElementView>(
  'elements',
  {},
  {
    idAttribute: 'uuid',
  }
);

const pageEntity = new schema.Entity<WProjectCustomizationPageView>(
  'pages',
  {
    elements: [elementEntity],
  },
  {
    idAttribute: 'uuid',
  }
);

export const customizationEntity = new schema.Entity<WProjectCustomizationFullView>(
  'customizations',
  {
    pages: [pageEntity],
  },
  {
    idAttribute: 'uuid',
    processStrategy: customization => _omit(customization, 'fields'),
  }
);

export const customizationObject = new schema.Object({
  pages: [pageEntity],
});

const projectSchema = new schema.Object<WProjectFullView>({
  customizations: [customizationEntity],
});

export default projectSchema;
