import React, { useEffect } from 'react';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { Global, css } from '@emotion/react';
import LogRocket from 'logrocket';
import { continueExistingLogRocketSession } from '@zola-helpers/client/dist/es/util/logrocket/logRocketUtils';
import { continueExistingSessionReplay } from '@zola-helpers/client/dist/es/util/mixpanel/sessionReplay';
import { TrackPageView } from 'components/util/TrackPageView';
import Favicons from 'components/common/Favicons';
import { isPostAuthNav3MobileOrDesktop } from 'util/isPostAuthNav3Active';
import { enhancedContext } from '@zola/tracking-contracts/src/plugins/enhancedContext';
import { enhancedTraits } from '@zola/tracking-contracts/src/plugins/enhancedTraits';
import { mixpanelContext } from '@zola/tracking-contracts/src/plugins/mixpanelContext';
import { COLORS3, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import { ZolaThemeProvider } from '../src/ZolaThemeProvider';
import { useStore } from '../src/store/nextStore';
import './styles/global/bootstrap.min.css';
import './styles/global/global.less';
import './styles/global/cards.less';
import '../src/nextPolyfills';

// Initialize Segment before any other rendering
if (typeof window !== 'undefined' && window.analytics) {
  continueExistingSessionReplay();
  window.analytics.register(enhancedContext, enhancedTraits(), mixpanelContext());
}

export default function App({ Component, pageProps, err }) {
  const store = useStore(pageProps.initialReduxState && JSON.parse(pageProps.initialReduxState));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.LogRocket = LogRocket;
      continueExistingLogRocketSession();
    }
  }, []);

  const isPostAuthNav3 = isPostAuthNav3MobileOrDesktop(pageProps.userContext);

  const globalStyles = css`
    .root-page-background {
      ${MEDIA_QUERY.DESKTOP_MASSIVE} {
        background-color: ${COLORS3.BLACK_005};
      }
    }
    .root-page-max-width {
      ${MEDIA_QUERY.DESKTOP_MASSIVE} {
        max-width: 1920px;
        min-height: 100vh;
        background-color: ${COLORS3.WHITE_100};
        border-right: 1px solid ${COLORS3.BLACK_030};
      }
      ${MEDIA_QUERY.DESKTOP} {
        .zola-ui.common-footer {
          position: static;
          width: calc(100% - 74px);
          margin-left: 74px;
        }
      }
    }
  `;

  return (
    <>
      <Head>
        <Favicons brand={pageProps.faviconBrand} />
      </Head>
      {isPostAuthNav3 ? <Global styles={globalStyles} /> : null}
      <Provider store={store}>
        <ZolaThemeProvider>
          <TrackPageView />
          <div id="wedding-modal-root" />
          <div className="root-page-background">
            <div id="zola-wedding-container" className="root-page-max-width">
              <Component {...pageProps} err={err} />
            </div>
          </div>
        </ZolaThemeProvider>
      </Provider>
    </>
  );
}
