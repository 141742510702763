import * as ActionTypes from '../actions/types/PublicWebsiteActionTypes';

export const initialState = {
  wedding: {},
  busy: false,
  page: {},
  entities: [],
  pages: [],
  theme: {},
  guestSearch: [],
  guestGroupRsvp: {},
  rsvp: {},
  rsvpShowSummary: false,
  guestRequestObject: [],
  guestRequestByUuid: {},
  guestRequestSubmitted: false,
  rsvpAccessForm: false,
  guestRequestCount: 0,
  zolaRegistry: {},
  token: '',
  cmsBanners: [],
  currentVirtualEvent: {},
  isCustomZIndex: false,
};

const publicReducer = (state = initialState, action) => {
  switch (action.type) {
    // busy
    case ActionTypes.REQUEST_WEDDING: {
      return { ...state, busy: true };
    }
    // busy, wedding, pages, theme
    case ActionTypes.RECEIVE_WEDDING: {
      return {
        ...state,
        busy: false,
        wedding: action.payload,
        pages: action.payload.nav_items,
        theme: action.payload.theme,
      };
    }
    case ActionTypes.TOGGLE_POI_MAPS: {
      return {
        ...state,
        wedding: {
          ...state.wedding,
          enable_poi_maps: !state.wedding.enable_poi_maps,
        },
      };
    }

    // busy
    case ActionTypes.REQUEST_GUEST_RSVP: {
      return { ...state, busy: true };
    }
    // busy, guestGroupRsvp
    case ActionTypes.RECEIVE_GUEST_RSVP: {
      return { ...state, busy: false, guestGroupRsvp: action.payload };
    }
    case ActionTypes.RESET_GUEST_RSVP: {
      return { ...state, busy: false, guestGroupRsvp: {} };
    }
    // busy, guestSearch
    case ActionTypes.RECEIVE_GUEST_SEARCH: {
      const guestSearch = action.payload.length === 0 ? ['NOT_FOUND'] : action.payload;
      return { ...state, busy: false, guestSearch };
    }
    // busy, guestSearch
    case ActionTypes.RESET_GUEST_SEARCH: {
      return { ...state, busy: false, guestSearch: [] };
    }

    // busy, guestRequestObject
    case ActionTypes.GET_GUEST_REQUESTS: {
      return { ...state, busy: false, guestRequestObject: action.payload };
    }
    // busy, guestRequestByUuid
    case ActionTypes.RECEIVE_GUEST_REQUEST_BY_UUID: {
      return { ...state, busy: false, guestRequestByUuid: action.payload };
    }
    // busy, guestRequestSubmitted
    case ActionTypes.RSVP_REQUEST_SUBMITTED: {
      return { ...state, busy: false, guestRequestSubmitted: action.payload };
    }
    // guestRequestByUuid, guestRequestObject
    case ActionTypes.GUEST_SUCCESSFULLY_SUBMITTED: {
      return { ...state, guestRequestByUuid: {}, guestRequestObject: [] };
    }
    // rsvpAccessForm, guestSearch
    case ActionTypes.HIDE_ACCESS_FORM: {
      return { ...state, rsvpAccessForm: action.payload, guestSearch: [] };
    }
    // guestRequestCount
    case ActionTypes.RECEIVE_GUEST_COUNT: {
      return { ...state, guestRequestCount: action.payload };
    }
    // token
    case ActionTypes.STORE_MOBILE_TOKEN: {
      return { ...state, token: action.payload };
    }
    // rsvpShowSummary
    case ActionTypes.SHOW_RSVP_SUMMARY: {
      return { ...state, rsvpShowSummary: action.payload };
    }
    case ActionTypes.RECEIVE_VIRTUAL_EVENT_PAGE:
      return { ...state, currentVirtualEvent: action.payload };
    default:
      return state;
  }
};

export default publicReducer;
