import { Group } from './types';

export const physicalSlides: Group = {
  title: 'Physical Gift',
  key: 'Pyisical_Gift',
  slides: [
    {
      title: 'For a kitchen gift you received before your wedding',
      content:
        'Thank you so much for the beautiful oak serving board! We can’t wait to use it during the many dinner parties you’ll be invited to this winter. Looking forward to celebrating with you at our wedding!',
      footer: '1 of 5',
    },
    {
      title: 'For drinkware you received before your wedding',
      content:
        'How did you know we were dreaming of those crystal champagne flutes?! They’re by far the most beautiful glasses in our home and we’ve never felt fancier. You’ll have to come over soon so we can toast to you! So excited to see you in June.',
      footer: '2 of 5',
    },
    {
      title: 'For a coffee gift you received after your wedding',
      content:
        'We had a blast dancing the night away with you at our wedding. We also can’t thank you enough for the espresso maker. Our mornings have never been brighter! We can’t wait to see you in Austin next month… all caffeinated up!',
      footer: '3 of 5',
    },
    {
      title: 'For cookware you received after your wedding',
      content:
        'Where have you been hiding those killer dance moves? Thank you so much for rocking out at our wedding. And we absolutely love our new cast iron skillet and cookbook! We’re already cooking up a storm. Hope to see you soon in New York!',
      footer: '4 of 5',
    },
    {
      title: 'For a gift-giver who couldn’t come to your wedding',
      content:
        'Thank you so much for the beer-brewing kit! We hope to be sipping backyard IPAs with you in no time. We missed you at the wedding, but know you were there in spirit. Can’t wait to see you when we’re back from our honeymoon!',
      footer: '5 of 5',
    },
  ],
};

export const cashSlides: Group = {
  title: 'Cash Gift',
  key: 'Cash_Gift',
  slides: [
    {
      title: 'For a gift you received before your wedding',
      content:
        'We can’t thank you enough for your generous gift! We’re so grateful and can’t wait to treat ourselves to a romantic dinner on our first night in Hawaii. So excited to see you in September!',
      footer: '1 of 5',
    },
    {
      title: 'For a friend who contributed to your custom cash fund',
      content:
        'We were so touched to receive your generous gift! Thanks to you, we’re now that much closer to adopting the puppy of our dreams. And you get all the free passes for playing with them. Can’t wait to see you at our wedding!',
      footer: '2 of 5',
    },
    {
      title: 'For a gift you received after your wedding',
      content:
        'Thank you so much for your generous gift! We’re now one step closer to getting those marble countertops we’ve been dreaming of. We were incredibly touched by your thoughtful speech at our wedding. Thank you for making that day so memorable! Can’t wait to see you soon.',
      footer: '3 of 5',
    },
    {
      title: 'For a guest who contributed to your favorite charity',
      content:
        'Thank you so much for your thoughtful donation to American Red Cross. We’re so lucky to have a friend like you in our lives. It was wonderful to see you at our wedding—we’ll cherish those memories forever.',
      footer: '4 of 5',
    },
    {
      title: 'For a gift-giver who couldn’t come to your wedding',
      content:
        'We’re so thankful for your generous gift! It’ll be a huge help when we start remodeling our guest room. We missed you at our wedding but know you were celebrating with us in spirit. Can’t wait for you to visit and stay with us in November!',
      footer: '5 of 5',
    },
  ],
};

export const cardSlides: Group = {
  title: 'Gift Card',
  key: 'Gift_Card',
  slides: [
    {
      title: 'For a wine gift you received before your wedding',
      content:
        'We’re beyond excited about the wine subscription you got us. Thank you so much! We can’t wait to try all the different bottles. We’ll break one out the next time you come over. Looking forward to celebrating with you in October!',
      footer: '1 of 5',
    },
    {
      title: 'For an entertainment gift you received before your wedding',
      content:
        'Thank you so much for the Hulu gift card—you know us too well! We can’t wait to take a break from planning and binge our favorite shows every weekend. So excited to see you at our wedding in June!',
      footer: '2 of 5',
    },
    {
      title: 'For a travel gift you received after your wedding',
      content:
        'Thank you so much for the Airbnb gift card! We just booked a beautiful villa for our honeymoon in Barcelona. We loved seeing you at our wedding and are touched you traveled so far to celebrate with us. Looking forward to spending more time together over the holidays!',
      footer: '3 of 5',
    },
    {
      title: 'For a spa and wellness gift you received after your wedding',
      content:
        'We can’t thank you enough for the massage package! We’re so excited to treat ourselves and unwind. We had such a blast celebrating with you at our wedding. Hope you have a wonderful summer!',
      footer: '4 of 5',
    },
    {
      title: 'For a gift-giver who couldn’t come to your wedding',
      content:
        'We’re so excited about the chocolate tour you got us! It’ll be such a fun treat after we get back from our honeymoon. Thank you so much! We missed you at the wedding (especially on the dance floor) but will be sure to send you lots of photos.',
      footer: '5 of 5',
    },
  ],
};

export const honeymoonSlides: Group = {
  title: 'Honeymoon Gift',
  key: 'Honeymoon_Gift',
  slides: [
    {
      title: 'For a surfing class you received before your wedding',
      content:
        'We’re beyond excited about the surfing class you got us. Thanks to you, our honeymoon in Hawaii will be that much more special. We’re so lucky to have a friend like you in our lives. Looking forward to celebrating with you in October!',
      footer: '1 of 5',
    },
    {
      title: 'For a wine tasting you received before your wedding',
      content:
        'We can’t thank you enough for the wine tasting you got us for our honeymoon in Florence! We’ll be sure to bring you back a bottle (or three!). Looking forward to seeing you at our wedding in September. Hope you’re having a wonderful summer!',
      footer: '2 of 5',
    },
    {
      title: 'For a cooking class you received after your wedding',
      content:
        'It was a joy to see you at our wedding! Thank you so much for the cooking class you got us for our honeymoon in Bali. We’ll be sure to send you photos and tell you all about it when we’re back. Hope you have a wonderful summer!',
      footer: '3 of 5',
    },
    {
      title: 'For a snorkeling tour you received after your wedding',
      content:
        'We’re so excited about the snorkeling tour you got us! We’re counting down the days until our honeymoon in Bora Bora. It was such a treat to celebrate with you at our wedding. Can’t wait to see you again when we’re back in Chicago!',
      footer: '4 of 5',
    },
    {
      title: 'For a gift-giver who couldn’t come to your wedding',
      content:
        'Thank you so much for the massage package you got us for our honeymoon in Kyoto! It’ll be so nice to unwind and treat ourselves during our trip. We missed you at the wedding but know you were there with us in spirit. Looking forward to seeing you over the holidays in Miami!',
      footer: '5 of 5',
    },
  ],
};
