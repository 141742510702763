import type { FavoriteCardSuiteUUIDs, FavoriteCardSuites } from 'favorites/types';
import * as ActionTypes from './types';
import type { FavoritesActions } from './types';

export type FavoritesReducerState = {
  paper: {
    loaded: boolean;
    suites: FavoriteCardSuites;
    uuids: FavoriteCardSuiteUUIDs;
  };
};

export const initialState: FavoritesReducerState = {
  paper: {
    loaded: false,
    suites: [],
    uuids: [],
  },
};

const favoritesReducer = (
  state = initialState,
  action: FavoritesActions
): FavoritesReducerState => {
  switch (action.type) {
    case ActionTypes.ADD_FAVORITE_CARD_SUITE: {
      return {
        ...state,
        paper: {
          ...state.paper,
          uuids: [...state.paper.uuids, action.payload],
        },
      };
    }
    case ActionTypes.REMOVE_FAVORITE_CARD_SUITE: {
      const newSuites = [...state.paper.suites];
      const suiteIndexToRemove = newSuites.findIndex(suite => suite.suiteUUID === action.payload);
      if (suiteIndexToRemove >= 0) newSuites.splice(suiteIndexToRemove, 1);

      const newUUIDs = [...state.paper.uuids];
      const uuidIndexToRemove = newUUIDs.indexOf(action.payload);
      if (uuidIndexToRemove >= 0) newUUIDs.splice(uuidIndexToRemove, 1);

      return {
        ...state,
        paper: {
          ...state.paper,
          suites: newSuites,
          uuids: newUUIDs,
        },
      };
    }
    case ActionTypes.RECEIVE_FAVORITE_CARD_SUITE_UUIDS: {
      return {
        ...state,
        paper: {
          ...state.paper,
          loaded: true,
          uuids: action.payload || [],
        },
      };
    }
    case ActionTypes.RECEIVE_FAVORITE_CARD_SUITES: {
      return {
        ...state,
        paper: {
          loaded: true,
          suites: action.payload || [],
          uuids: action.payload.map(suite => suite.suiteUUID),
        },
      };
    }
    default:
      return state;
  }
};

export default favoritesReducer;
