import type {
  WProjectFullView,
  WProjectCustomizationElementView,
  WProjectCustomizationFullView,
} from '@zola/svc-web-api-ts-client';
import {
  UpdateQRCodeStateAction,
  UPDATE_QR_CODE_STATE,
  UpdatePageQRCodeAction,
  UPDATE_PAGE_QR_CODE,
} from 'cards/actions/qrCodeActionTypes';
import { ProjectActionTypes } from 'cards/actions/projectActionTypes';
import { isValidHttpUrl } from 'cards/util/isValidHttpUrl';

export interface PageQRCodeData {
  generated: boolean;
  reviewed: boolean;
  verified: boolean;
  enabled: boolean;
  type: string;
  color: string;
  qrCodeURL: string;
  qrElement: WProjectCustomizationElementView | null;
}

export interface QRCodeState {
  loading: boolean;
  mobileGatewaySeen: boolean | null;
  byPageUUID: Record<string, PageQRCodeData>;
}

const initialState: QRCodeState = {
  loading: false,
  mobileGatewaySeen: null,
  byPageUUID: {},
};

interface CustomizationReceiveAction {
  type: typeof ProjectActionTypes.RECEIVE_CUSTOMIZATION;
  payload: { rawCustomization: WProjectCustomizationFullView };
}
interface ProjectReceiveAction {
  type: typeof ProjectActionTypes.RECEIVE_PROJECT;
  payload: { isTestProject: boolean; project: WProjectFullView };
}

const isQRElement = (element: WProjectCustomizationElementView): boolean =>
  !!element?.tag?.trim()?.startsWith('QR_CODE::');

const extractQRDataByPageId = (
  customizations: WProjectCustomizationFullView[],
  state: QRCodeState
) => {
  const qrDataByPageUUID: Record<string, PageQRCodeData> = {};
  customizations.forEach(customization => {
    customization.pages?.forEach(page => {
      if (page.uuid) {
        const qrElement = page?.elements?.find(isQRElement);
        const qrDataString = qrElement?.tag
          ?.trim()
          ?.split('QR_CODE::')
          ?.find((s?: string) => !!s);
        const qrData = qrDataString && JSON.parse(qrDataString);
        const urlText = qrData?.url || '';

        if (qrElement && qrData) {
          qrDataByPageUUID[page.uuid] = {
            ...state.byPageUUID[page.uuid],
            generated: true,
            enabled: qrData.enabled,
            type: qrData.url_type,
            color: qrData.color || '000000',
            qrCodeURL: qrData.url || '',
            qrElement,
            verified: urlText.length === 0 || isValidHttpUrl(urlText),
          };
        } else {
          const previousData = state.byPageUUID[page.uuid];
          const previousUrl = previousData?.qrCodeURL || '';

          qrDataByPageUUID[page.uuid] = {
            generated: false,
            reviewed: false,
            enabled: false,
            qrElement: null,
            type: previousData?.type || 'WEDDING_WEBSITE',
            color: previousData?.color || '000000',
            qrCodeURL: previousUrl,
            verified: previousUrl.length === 0 || isValidHttpUrl(previousUrl),
          };
        }
      }
    });
  });
  return qrDataByPageUUID;
};

const qrCodeReducer = (
  state = initialState,
  action:
    | UpdateQRCodeStateAction
    | UpdatePageQRCodeAction
    | ProjectReceiveAction
    | CustomizationReceiveAction
): QRCodeState => {
  switch (action.type) {
    case UPDATE_QR_CODE_STATE: {
      return { ...state, ...action.payload };
    }
    case UPDATE_PAGE_QR_CODE: {
      const { pageUUID, data } = (action as UpdatePageQRCodeAction).payload;
      return {
        ...state,
        byPageUUID: {
          ...state.byPageUUID,
          [pageUUID]: {
            ...state.byPageUUID[pageUUID],
            ...data,
          },
        },
      };
    }
    case ProjectActionTypes.RECEIVE_CUSTOMIZATION: {
      const customization = (action as CustomizationReceiveAction).payload?.rawCustomization;
      if (!customization) return state;
      const qrDataByPageUUID = extractQRDataByPageId([customization], state);
      return {
        ...state,
        byPageUUID: {
          ...state.byPageUUID,
          ...qrDataByPageUUID,
        },
      };
    }
    case ProjectActionTypes.RECEIVE_PROJECT: {
      const project = (action as ProjectReceiveAction).payload?.project;
      if (!project?.customizations) return state;
      return {
        ...state,
        byPageUUID: extractQRDataByPageId(project.customizations, state),
      };
    }
    default: {
      return state;
    }
  }

  return state;
};

export default qrCodeReducer;
