export const DEFAULT_PAPER_TYPE_VALUE = 'smooth'; // use smooth as default to prevent bug with foil cards that dont have eggshell paper-type as an option
export const DEFAULT_SHAPE_VALUE = 'square';

export const ALL_FILTER_COLORS = [
  'BLACK',
  'BLUE',
  'BROWN',
  'GREEN',
  'GREY',
  'ORANGE',
  'PINK',
  'PURPLE',
  'RED',
  'WHITE',
  'YELLOW',
];

export const TEXT_ALIGNMENT_OPTIONS = ['LEFT', 'CENTER', 'RIGHT'];

export const PAGES = [
  'FRONT',
  'INSIDE',
  'WRITE',
  'BACK',
  'RSVP',
  'RSVP ENVELOPE',
  'ENVELOPE',
  'FRONT',
  'DETAILS',
  'FREE SAMPLES',
  'SHARE',
];
export const PAGE_URLS = {
  FRONT: 'detailFront',
  INSIDE: 'detailInside',
  WRITE: 'detailWrite',
  ASSIGN: 'detailAssign',
  BACK: 'detailBack',
  RSVP: 'rsvp',
  'RSVP ENVELOPE': 'rsvpEnvelope',
  ENVELOPE: 'envelope',
  'DIGITAL FRONT': 'front',
  DETAILS: 'details',
  'FREE SAMPLES': 'freeSamples',
  SHARE: 'share',
};

export const CARD_SIZES = {
  petite: 'petite',
  magnet: 'magnet',
  classic: '5x7',
  folded: 'folded-petite',
  postcard: 'postcard',
  tall: '4x8',
  smallFolded: 'folded-placecard-standard',
  beverage: 'beverage',
};

export const MIN_DPI = 300;

export const HIGHEST_ALLOWED_MIN_REQUIREMENT = 1050;

// TODO: get rid of this to ease the creation of new card types
export const MAX_CARD_DIMENSION_INCHES = {
  [CARD_SIZES.petite]: 5.5,
  [CARD_SIZES.magnet]: 5.5,
  [CARD_SIZES.classic]: 7,
  [CARD_SIZES.folded]: 5.5, // height is smaller than width when folded, so use width
  [CARD_SIZES.postcard]: 5.5,
  [CARD_SIZES.tall]: 8,
  [CARD_SIZES.smallFolded]: 3.5,
  [CARD_SIZES.beverage]: 4.75,
};

export const SEASONS = ['Fall', 'Winter', 'Spring', 'Summer'];

export const FOLDED_SIZES = [CARD_SIZES.folded, CARD_SIZES.smallFolded];

export const SIZES_WITHOUT_SHAPES = [
  CARD_SIZES.postcard,
  CARD_SIZES.folded,
  CARD_SIZES.smallFolded,
];

export const DISABLED_FOLDED_PAPER = ['recycled', 'double-thick', 'triple-thick'];
export const DISABLED_POSTCARD_PAPER = [
  'pearlized',
  'linen',
  'felt',
  'double-thick',
  'triple-thick',
];

export const DISABLED_CUSTOM_FOIL_PAPER = ['felt'];

export const NON_STAMP_FOIL_VALUES = ['none', 'custom'];

// Used to align place card text elements via flexbox
export const VERTICAL_ALIGN_TO_ALIGN_ITEMS = {
  top: 'start',
  bottom: 'end',
  middle: 'center',
};
export const TEXT_ALIGN_TO_JUSTIFY_CONTENT = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};
