import React from 'react';

const Favicons = ({ brand = 'WEDDINGS' }: { brand: 'WEDDINGS' | 'BABY' }) => {
  return brand === 'BABY' ? (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-baby/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-baby/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-baby/favicon-16x16.png"
      />
      <link
        rel="manifest"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-baby/site.webmanifest"
      />
      <link
        rel="mask-icon"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-baby/safari-pinned-tab.svg"
        color="#495fe8"
      />
      <link
        rel="shortcut icon"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-baby/favicon.ico"
      />
      <meta name="msapplication-TileColor" content="#d5e3fe" />
      <meta
        name="msapplication-config"
        content="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-baby/browserconfig.xml"
      />
      <meta name="theme-color" content="#d5e3fe" />
    </>
  ) : (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-rebrand/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-rebrand/android-chrome-192x192.png"
        sizes="192x192"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-rebrand/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-rebrand/favicon-16x16.png"
        sizes="16x16"
      />
      <link
        rel="manifest"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon/manifest.json"
      />
      <link
        rel="mask-icon"
        href="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-rebrand/safari-pinned-tab.svg"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="msapplication-TileImage"
        content="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/favicon-rebrand/mstile-150x150.png"
      />
      <meta name="theme-color" content="#ffffff" />
    </>
  );
};

export default Favicons;
