import type {
  WCmsEventPageView,
  WCmsEventView,
  WSuggestedAttireOptionView,
  WSuggestedRsvpQuestionView,
} from '@zola/svc-web-api-ts-client';

export const REQUEST_EVENT = 'zola/event/request_event';
export const RECEIVE_ZOOM_EVENT = 'zola/event/receive_zoom_event';
export const RESET_ZOOM_EVENT = 'zola/event/reset_zoom_event';
export const REQUEST_EVENTS = 'zola/event/request_events';
export const RECEIVE_EVENTS = 'zola/event/receive_events';
export const RECEIVE_EVENTS_BY_DATE = 'zola/event/receive_events_by_date';
export const SELECT_EVENT = 'zola/event/select_event';
export const INITIALIZE_SELECTED_EVENT = 'zola/event/initialize_selected_event';
export const SUGGESTED_ATTIRE_OPTIONS_REQUESTED = 'zola/event/suggested_attire_options_requested';
export const SUGGESTED_ATTIRE_OPTIONS_RECEIVED = 'zola/event/suggested_attire_options_received';
export const SUGGESTIONS_REQUESTED = 'zola/event/suggestions_requested';
export const SUGGESTIONS_RECEIVED = 'zola/event/suggestions_received';
export const REQUEST_EVENTS_RSVP_PAGE = 'zola/event/request_events_rsvp_page';
export const RECEIVE_EVENTS_RSVP_PAGE = 'zola/event/receive_events_rsvp_page';
export const RECEIVE_MIGRATION_EVENTS = 'zola/event/receive_migration_events';
export const REQUEST_MIGRATION_EVENTS = 'zola/event/request_migration_events';
export const BEGIN_MIGRATING = 'zola/event/begin_migrating';
export const FINISH_MIGRATING = 'zola/event/finish_migrating';

type RequestEventActionType = {
  type: typeof REQUEST_EVENT;
};

type ReceiveZoomEventActionType = {
  type: typeof RECEIVE_ZOOM_EVENT;
  payload: WCmsEventView;
};

type ResetZoomEventActionType = {
  type: typeof RESET_ZOOM_EVENT;
};

type RequestEventsActionType = {
  type: typeof REQUEST_EVENTS;
};

type RequestEventsRsvpPageActionType = {
  type: typeof REQUEST_EVENTS_RSVP_PAGE;
};

type ReceiveEventsActionType = {
  type: typeof RECEIVE_EVENTS;
  payload: {
    events?: WCmsEventView[];
  };
};

type ReceiveEventsByDateActionType = {
  type: typeof RECEIVE_EVENTS_BY_DATE;
  payload: {
    groupedEventsByDate: WCmsEventPageView;
  };
};

type RequestSuggestedAttireOptionsActionType = {
  type: typeof SUGGESTED_ATTIRE_OPTIONS_REQUESTED;
};

type ReceiveSuggestedAttireOptionsActionType = {
  type: typeof SUGGESTED_ATTIRE_OPTIONS_RECEIVED;
  payload: WSuggestedAttireOptionView[];
};

type ReceiveEventsRsvpPageActionType = {
  type: typeof RECEIVE_EVENTS_RSVP_PAGE;
  payload: {
    events?: WCmsEventView[];
    hidden?: boolean;
    id?: number;
  };
};

type RequestSuggestionsActionType = {
  type: typeof SUGGESTIONS_REQUESTED;
};

type ReceiveSuggestionsActionType = {
  type: typeof SUGGESTIONS_RECEIVED;
  payload: WSuggestedRsvpQuestionView[];
};

type SelectEventActionType = {
  type: typeof SELECT_EVENT;
  payload: {
    selectedId?: number;
  };
};

type InitializeSelectedEventActionType = {
  type: typeof INITIALIZE_SELECTED_EVENT;
};

export type EventActionTypes =
  | RequestEventActionType
  | ReceiveZoomEventActionType
  | ResetZoomEventActionType
  | RequestEventsActionType
  | RequestEventsRsvpPageActionType
  | ReceiveEventsActionType
  | ReceiveEventsByDateActionType
  | RequestSuggestedAttireOptionsActionType
  | ReceiveSuggestedAttireOptionsActionType
  | ReceiveEventsRsvpPageActionType
  | RequestSuggestionsActionType
  | ReceiveSuggestionsActionType
  | SelectEventActionType
  | InitializeSelectedEventActionType;
