import type { CardTemplateBackgroundView } from '@zola/svc-web-api-ts-client';

export const RECEIVE_BACKGROUND_ASSETS = 'RECEIVE_BACKGROUND_ASSETS';

export type ReceiveBackgroundAssetsAction = {
  type: typeof RECEIVE_BACKGROUND_ASSETS;
  payload: CardTemplateBackgroundView[];
};

export type ExtraCustomizationActions = ReceiveBackgroundAssetsAction;
