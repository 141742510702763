const GuestAffiliation = {
  UNKNOWN: 'Select...',
  PRIMARY_FAMILY: "Primary's Family",
  PRIMARY_WEDDING_PARTY: "Primary's Wedding Party",
  PRIMARY_FRIEND: "Primary's Friend",
  PRIMARY_FAMILY_FRIEND: "Primary's Family Friend",
  PARTNER_FAMILY: "Partner's Family",
  PARTNER_WEDDING_PARTY: "Partner's Wedding Party",
  PARTNER_FRIEND: "Partner's Friend",
  PARTNER_FAMILY_FRIEND: "Partner's Family Friend",
  BOTH: "Primary and Partner's Friend",
};

export const getGuestAffiliationOptions = (primaryFirstName: string, partnerFirstName: string) =>
  Object.keys(GuestAffiliation).map(key => {
    const label = (GuestAffiliation as any)[key]
      .replace("Primary's", `${primaryFirstName}'s`)
      .replace('Primary', primaryFirstName)
      .replace("Partner's", `${partnerFirstName}'s`);
    return { label, value: key };
  });

export default (primaryFirstName: string, partnerFirstName: string) =>
  Object.keys(GuestAffiliation).map(k => {
    const title = (GuestAffiliation as any)[k]
      .replace("Primary's", `${primaryFirstName}'s`)
      .replace('Primary', primaryFirstName)
      .replace("Partner's", `${partnerFirstName}'s`);
    return { title, value: k };
  });
