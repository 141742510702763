import { useMemo } from 'react';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import toasts from '@zola-helpers/client/dist/es/redux/toasts/toastsReducer';
import thunk from 'redux-thunk';

// Reducers
import websitePlpReducer from 'pages/WebsiteDesignsPLPSSR/reducer/websitePlpReducer';
import websitePdpReducer from 'pages/WebsiteDesignPdp/reducer/websitePdpReducer';
import favorites from 'favorites/redux/reducer';
import modal from '../reducers/modal';
import onboard from '../reducers/onboard';
import auth from '../reducers/auth';
import guestList from '../reducers/guestList';
import user from '../reducers/user';

import checklist from '../reducers/checklist';
import publicWebsite from '../reducers/public';
import moduleGroup from '../reducers/moduleGroup';
import registry from '../reducers/registry';
import registryCollections from '../reducers/registryCollections';
import cart from '../reducers/cart';

import external from '../reducers/external';
import uploadcare from '../reducers/uploadcare';
import events from '../reducers/events';
import guestCollection from '../reducers/guestCollection';
import seatingChart from '../reducers/seatingChart';
import guestRail from '../reducers/guestRail';
import registryBulkImport from '../reducers/registryBulkImport';
import website from '../reducers/website';
import wedding from '../reducers/wedding';
import zoom from '../reducers/zoom';

import cards from '../cards/reducers';

const reducers = {
  cards,
  modal,
  auth,
  cart,
  checklist,
  events,
  external,
  favorites,
  guestCollection,
  guestList,
  guestRail,
  moduleGroup,
  Onboard: onboard,
  publicWebsite,
  registry,
  registryBulkImport,
  registryCollections,
  seatingChart,
  user,
  website,
  wedding,
  uploadcare,
  zoom,
  toasts,
  websitePlp: websitePlpReducer,
  websitePdp: websitePdpReducer,
};

/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */
const composeEnhancers =
  typeof window !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose; // TODO disable this in prodction

const nextReducers = combineReducers(reducers);

function initStore(preloadedState) {
  return createStore(nextReducers, preloadedState, composeEnhancers(applyMiddleware(thunk)));
}

let nextStore;
export const initializeStore = preloadedState => {
  // eslint-disable-next-line no-underscore-dangle
  let _store = nextStore ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && nextStore) {
    _store = initStore({
      ...nextStore.getState(),
      ...preloadedState,
    });
    // Reset the current store
    nextStore = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!nextStore) nextStore = _store;

  return _store;
};

export function useStore(initialState) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const nextStore = useMemo(() => initializeStore(initialState), [initialState]);
  return nextStore;
}
