import type { PLPPhotoPreviewState } from 'cards/reducers/plpPhotoPreview';

export const UPDATE_PLP_PHOTO_PREVIEW_STATE = 'UPDATE_PLP_PHOTO_PREVIEW_STATE';
export interface UpdatePLPPhotoPreviewState {
  type: typeof UPDATE_PLP_PHOTO_PREVIEW_STATE;
  payload: Partial<PLPPhotoPreviewState>;
}

export function updatePlpPhotoPreviewState(
  payload: Partial<PLPPhotoPreviewState>
): PLPPhotoPreviewAction {
  return {
    type: UPDATE_PLP_PHOTO_PREVIEW_STATE,
    payload,
  };
}

export type PLPPhotoPreviewAction = UpdatePLPPhotoPreviewState;
