import type {
  WZoomConnectionView,
  WZoomMeetingView,
  WZoomUserView,
} from '@zola/svc-web-api-ts-client';

export const REQUEST_ACCESS_TOKEN = 'zola/zoom/REQUEST_ACCESS_TOKEN';
export const RECEIVE_ACCESS_TOKEN = 'zola/zoom/RECEIVE_ACCESS_TOKEN';
export const REMOVE_ACCESS_TOKEN = 'zola/zoom/REMOVE_ACCESS_TOKEN';
export const REQUEST_MEETING = 'zola/zoom/REQUEST_MEETING';
export const RECEIVE_MEETING = 'zola/zoom/RECEIVE_MEETING';
export const REQUEST_CONNECTION_SETTINGS = 'zola/zoom/REQUEST_CONNECTION_SETTINGS';
export const RECEIVE_CONNECTION_SETTINGS = 'zola/zoom/RECEIVE_CONNECTION_SETTINGS';
export const REQUEST_CONNECTION = 'zola/zoom/REQUEST_CONNECTION';
export const RECEIVE_CONNECTION = 'zola/zoom/RECEIVE_CONNECTION';
export const RECEIVE_ZOOM_PLAN_TYPE = 'zola/zoom/RECEIVE_ZOOM_PLAN_TYPE';

type RequestAccessTokenActionType = {
  type: typeof REQUEST_ACCESS_TOKEN;
  payload: { code: string };
};

type ReceiveAccessTokenActionType = {
  type: typeof RECEIVE_ACCESS_TOKEN;
  payload: WZoomConnectionView;
};

type RemoveAccessTokenActionType = {
  type: typeof REMOVE_ACCESS_TOKEN;
};

type RequestMeetingActionType = {
  type: typeof REQUEST_MEETING;
};

type ReceiveMeetingActionType = {
  type: typeof RECEIVE_MEETING;
  payload: WZoomMeetingView;
};

type RequestConnectionActionType = {
  type: typeof REQUEST_CONNECTION;
};

type ReceiveConnectionActionType = {
  type: typeof RECEIVE_CONNECTION;
  payload: WZoomConnectionView;
};

type RequestConnectionSettingsActionType = {
  type: typeof REQUEST_CONNECTION_SETTINGS;
};

export type MeetingSignatureType = {
  signature: string;
  sdkKey: string;
};

type ReceiveConnectionSettingsActionType = {
  type: typeof RECEIVE_CONNECTION_SETTINGS;
  payload: MeetingSignatureType;
};

type ReceiveZoomPlanTypeActionType = {
  type: typeof RECEIVE_ZOOM_PLAN_TYPE;
  payload: WZoomUserView;
};

export type ZoomActionTypes =
  | RequestAccessTokenActionType
  | ReceiveAccessTokenActionType
  | RemoveAccessTokenActionType
  | RequestMeetingActionType
  | ReceiveMeetingActionType
  | RequestConnectionActionType
  | ReceiveConnectionActionType
  | RequestConnectionSettingsActionType
  | ReceiveConnectionSettingsActionType
  | ReceiveZoomPlanTypeActionType;
