import sortBy from 'lodash/sortBy';

// TODO: ? Can strip the Other and make this simply an alphabetize util function
export default function alphabetizeTraditions(traditions) {
  const otherTemplate = traditions.filter(t => t.title === 'Other');
  const remainingTemplatesAlphabetized = sortBy(
    traditions.filter(t => t.title !== 'Other'),
    [t => t.title]
  );
  return [...remainingTemplatesAlphabetized, ...otherTemplate];
}
