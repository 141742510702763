import _dropRight from 'lodash/dropRight';
import ActionTypes from '../actions/formHistoryActionTypes';

const initialState = {
  past: [],
};

function formChanges(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.PUSH_CHANGE: {
      const changeSet = action.payload;
      return {
        ...state,
        past: [...state.past, changeSet],
      };
    }
    case ActionTypes.POP_CHANGE: {
      const nextPast = _dropRight(state.past);
      return {
        ...state,
        past: nextPast,
      };
    }
    case ActionTypes.CLEAR_HISTORY: {
      return {
        ...state,
        past: [],
      };
    }
    case ActionTypes.UPDATE_HISTORY_ELEMENT: {
      const { oldElementUUID, newElementUUID } = action.payload;
      const updatedHistory = [...state.past];
      updatedHistory.forEach(entry => {
        entry.forEach(elm => {
          const refreshedData = {};

          if (elm.elementUUID === oldElementUUID) {
            refreshedData.elementUUID = newElementUUID;
          }
          if (elm.fieldName === 'linked_element_uuid' && elm.currentValue === oldElementUUID) {
            refreshedData.currentValue = newElementUUID;
          }

          Object.assign(elm, refreshedData);
        });
      });
      return {
        ...state,
        past: updatedHistory,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default formChanges;
