import { FavoriteCardSuiteUUIDs, FavoriteCardSuites } from 'favorites/types';

//  Action type keys
export const ADD_FAVORITE_CARD_SUITE = 'zola/favorites/add_favorite_card_suite';
export const REMOVE_FAVORITE_CARD_SUITE = 'zola/favorites/remove_favorite_card_suite';
export const RECEIVE_FAVORITE_CARD_SUITE_UUIDS = 'zola/favorites/receive_favorite_card_suite_uuids';
export const RECEIVE_FAVORITE_CARD_SUITES = 'zola/favorites/receive_favorite_card_suites';

// Actions
export type AddFavoriteCardSuiteAction = {
  type: typeof ADD_FAVORITE_CARD_SUITE;
  payload: string;
};

export type RemoveFavoriteCardSuiteAction = {
  type: typeof REMOVE_FAVORITE_CARD_SUITE;
  payload: string;
};

export type ReceiveFavoriteCardSuiteUUIDsAction = {
  type: typeof RECEIVE_FAVORITE_CARD_SUITE_UUIDS;
  payload: FavoriteCardSuiteUUIDs;
};

export type ReceiveFavoriteCardSuitesAction = {
  type: typeof RECEIVE_FAVORITE_CARD_SUITES;
  payload: FavoriteCardSuites;
};

export type FavoritesActions =
  | AddFavoriteCardSuiteAction
  | RemoveFavoriteCardSuiteAction
  | ReceiveFavoriteCardSuiteUUIDsAction
  | ReceiveFavoriteCardSuitesAction;
